<template>
  <h1>Mes expériences</h1>
  <div class="row">
    <div class="col-lg-12">
        <div class="ibox">            
            <div class="ibox-content" v-if="experiences.length > 0">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover dataTables-example" >
                        <thead>
                            <tr>
                                <th>Offre</th>
                                <th>Date</th>                                
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="gradeX" v-for="experience in experiences" :key="experience.id">
                                <td>{{ experience.offre.titre }}</td>
                                <td>
                                  <div v-if="experience.date">
                                    {{ $filters.formatDate(experience.date.date) }}
                                  </div>
                                </td>                                
                                <td class="center">   
                                  <router-link  :to="{ name: 'experience', params: { uuid: experience.uuid }}"><i class="fa fa-edit text-navy"></i></router-link>              
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>


export default {
  name: 'Experiences',
  data() {
    return {  
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {      
      this.$store.dispatch('getExperiences');
    }
  },
  computed: {
    experiences () {
      return this.$store.state.experiences.experiences
    },
  },
  components: {
  },
};
</script>